import React from "react";
import { graphql, Link } from 'gatsby';
import Img from "gatsby-image";
import Layout from "../../layouts/layout";

const contactReturn = ({ data: {pageData, hero} }) => {

    hero.buttons = (
        <div className="mt-8">
            <Link to="/locations">
            <button className="bg-transparent hover:bg-mane-yellow text-white text-xs font-semibold font-aktivcd hover:text-gray-800 uppercase py-3 px-8 border border-white hover:border-transparent tracking-tight mr-6">
                locations
            </button>
            </Link>
        </div>
    )


return (
<Layout hero={hero}>
    <div className="container mx-auto mb-16 px-4">
        {pageData.text && 
        <div className="nested-html mx-auto text-justify"
             dangerouslySetInnerHTML={{ __html: pageData.text.childMarkdownRemark.html }}
        />                    
        }
    </div>
</Layout>
)};

export const query = graphql`
query contactThankYou {
     hero: contentfulContact (slug: {eq: "contact"}) {
        heroBackgroundImage {
          resize(width: 1440) {
           src
          }
        }
        heroHeading
        heroHeadingTwo
      }
     pageData: contentfulContactConfirmationPage(formName: {eq: "contact"}) {
        text {
           childMarkdownRemark {
                       html
                     }
             }
        }
}
`;


export default contactReturn;
